.menu-wrapper{
    position: relative;
    cursor: pointer;
    width: 40px;
    height: 40px;
}

.menu{
    position: absolute;
    top: 46px;
    right: 2px;
    width: 140px;
    background: white;
    padding: 0px 10px 0 10px;
    box-shadow: 1px 2px 3px 0 rgba(21, 21, 21, 0.6);
    transition: all 0.3s cubic-bezier(.35,0,.25,1);
    transform-origin: top right;
    font-size: 0.8em;
    z-index: 999;
}

.menu-icon{
    width: 28px;
    margin-right: 11px;
    position: relative;
    top: 5px;
}

.menu-open{
    visibility: visible;
    transform: scale(1);
    opacity: 1;
}

.menu-close{
    visibility: hidden;
    transform: scale(0);
    opacity: 0;
}

.nav > li,span,p{
    font-size: 16px;  
}

.nav {
    list-style: none;
    -webkit-padding-start: 0px
}
  
.nav > li{
    padding: 16px 0;
}

.nav > li > span {
    padding: 10px 15px;
    text-decoration: none;
    color: #1a1a1a;
}
  
.nav > li > span:hover{
    text-decoration: none;  
}
  
.nav > li > li:hover{
    background: #efefef
}