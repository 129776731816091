#visibilityIcon{
  position: absolute;
  top: 6px;
  left: 10px;
}

#generatePasswordIcon{
  position: absolute;
  top: 6px;
  left: 10px;
}