input[type='file'] {
    opacity:0;
    display: none;  
}

.recharts-legend-item{
    margin-top: 10px;
}

.recharts-surface, .recharts-wrapper{
    position: relative;
    z-index: -1;
}